<template>
    <section>
        <header-page title="Foro">
            <div class="col-auto ml-auto">
                <button class="bg-general text-white f-15 cr-pointer br-12 py-2 px-5 shadow" @click="responder(null)">
                    Responder
                </button>
            </div>
        </header-page>
        <div class=" pl-3 pr-0">
            <div class="row j-center mx-0 my-3 mt-4 ">
                <!-- Header -->
                <div class="col-12">
                    <div class="row mx-0 mb-3">
                        <div class="col px-0  f-17">
                            <p class="tres-puntos my-2 f-600">
                                {{ foro.titulo }}
                            </p>
                            <p class="my-2">
                                {{ foro.texto }}
                            </p>
                        </div>
                        <div class="col-auto d-flex flex-column justify-content-start">
                            <el-tooltip placement="left-start" content="Visualización" effect="light">
                                <div class="row mx-0 f-18">
                                    <i class="icon-eye text-general" /> {{ foro.visualizaciones_count }}
                                </div>
                            </el-tooltip>
                            <el-tooltip placement="left-start" content="Respuestas" effect="light">
                                <div class="row mx-0 f-18">
                                    <i class="icon-message-arrow-left text-general" /> {{ foro.mensajes_count }}
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                    <!-- ticket name && button -->
                    <div class="row mx-0 mb-3 border-bottom pb-3">
                        <div class="col-auto px-0 d-flex a-center">
                            <img :src="foro.creador.imagen " width="55" height="55" class="position-absolute obj-cover rounded-circle border-grey" style="top:-13px;left:-9px;" />
                            <div class="ticket-name py-1 pr-2 pl-5 ml-2 tres-puntos text-center" style="max-width:170px;">
                                {{ foro.creador.nombre }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="scrollGeneral" v-loading="loading" class="px-0 custom-scroll overflow-auto position-relative" style="height:calc(50vh - 40px);" @scroll.passive="handleScroll">
                <button v-show="mensajes.length > 0" class="scrollToTopBtn position-absolute" @click="restablecerScroll">
                    👇
                </button>
              
                <div class="row mx-0 my-3">
                    <div class="col-auto px-4" />
                    <div class="col px-0">
                        <!-- scroll -->
                        <infinite-loading ref="infiniteLoading" direction="top" @infinite="listarMensajes">
                            <div slot="spinner">
                                <div>
                                    Cargando...
                                </div>
                            </div>
                            <div slot="no-more" class="text-muted">
                                <span v-if="true">No hay más mensajes</span>
                            </div>
                            <div slot="no-results" class="text-muted">
                                <span v-if="mensajes.length <=0">
                                    No se encuentran mensajes
                                </span>
                            </div>
                        </infinite-loading>
                        <!-- scroll -->
                        <div v-for="(msg, idx) in mensajes" :key="idx" class="row mx-0">
                            <div class="col-12 my-3 px-0">
                                <p>
                                    {{ msg.mensaje }}
                                </p>
                                <div class="row py-3 mx-0 mb-0 px-0 pb-3 border-bottom">
                                    <div class="col-auto px-0 d-flex flex-column a-center">
                                        <img :src="msg.creador.imagen" width="55" height="55" class="position-absolute obj-cover rounded-circle border-grey" style="top:-13px;left:-9px;" />
                                        <div class="ticket-name-responsable py-1 pr-2 ml-2 pl-5 tres-puntos text-center" style="max-width:170px;">
                                            {{ msg.creador.nombre }}
                                        </div>
                                        <i v-if="msg.creador.responsable" class="pl-5 f-15 text-light-gris">
                                            <i class="icon-star-circled f-18 text-yellow" /> Responsable
                                        </i>
                                    </div>
                                    <div v-loading="loadingLike" class="col-auto d-middle">
                                        <i :class="`${msg.my_like ? 'icon-thumb-up' : 'icon-thumb-up-outline'} text-general f-18 cr-pointer`" @click="toogleLike(msg)" /> 
                                        {{ msg.likes_count }}
                                    </div>
                                    <div class="col-auto ml-auto">
                                        <div class=" cr-pointer py-2 text-general f-600" @click="responder(msg.id)">
                                            Responder 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto estilos-hijos mb-3 px-3" />
                            <div class="col">
                                <mensaje-hijo v-for="(hijo, idx2) in msg.hijos" :key="idx2" :msg="hijo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <modalResponder ref="modalResponder" />
    </section>
</template>

<script>
import Foros from '~/services/foros/foros'
import InfiniteLoading from 'vue-infinite-loading'
export default {
    components: {
        InfiniteLoading,
        mensajeHijo: () => import('./components/mensajeHijo'),
        modalResponder: () => import('./partials/modalResponder')
    },
    data(){
        return {
            id_foro: parseInt(this.$route.params.id_foro),
            loading: false,
            loadingLike: false,
            src_image: 'https://us.123rf.com/450wm/vadymvdrobot/vadymvdrobot1903/vadymvdrobot190306154/119989030-portrait-of-a-beautiful-pensive-young-woman-casualy-dressed-standing-isolated-over-white-background.jpg?ver=6',
            cant_likes: 0,
            respuesta: '',
            foro: {
                titulo: '',
                texto: '',
                mensajes_count: 0,
                visualizaciones_count: 0,
                creador:{
                    nombre: '',
                    imagen: null
                }
            },
            mensajes: [],
            page:1,
            nameCanal: 'mac_learning_laravel_foros'
        }
    },
    mounted(){
        this.limpiarForo()
        this.listarForo()
        this.subscribeChanel()
    },
    methods: {
        responder(idPadre){
            this.$refs.modalResponder.toggle(idPadre)
        },
        subscribeChanel(){
            if (this.sockets=== undefined) this.notificacion('Mensaje','EL socket no esta importado','warning')
            this.sockets.subscribe(`${this.nameCanal}_${this.id_foro}`, (value)=>{
                const {mensaje} = JSON.parse(value)
                this.foro.mensajes_count = this.foro.mensajes_count +1
                if(mensaje.id_padre){// significa que es un hijo
                    const index = this.mensajes.findIndex(e => e.id === mensaje.id_padre)
                    if(index >= 0){
                        this.mensajes[index].hijos.push(mensaje)
                    }

                }else{// es un padre scroll abajo
                    const newPadre = {...mensaje, hijos: []}
                    this.mensajes.push(newPadre)
                    this.deslizar()
                }

            })
        },
        delay_scroll(ms=100){
            return new Promise(resolve => setTimeout(resolve, ms));
        }, 
        async deslizar(){
            if (this.necesitaDeslizar()){
   
                await this.delay_scroll(100)
                this.restablecerScroll()
                console.log('hace');
            } else {
                console.log('No lo hace');
            }
        },
        async listarForo(){
            try {
                // this.loading = true
                const {data:{data}} = await Foros.listarDetalleForo({id_foro: this.id_foro})
                // this.loading = false
                this.foro = data.foro
            } catch (e){
                // this.loading = false
                this.error_catch(e)
            }
        },
        async listarMensajes($state= false){
            try {
                const state = $state
                var params = {
                    page: this.page,
                    id_foro: this.id_foro
                }
                this.loading = true
                const {data:{data}} = await Foros.listarMensajesForo(params)
                this.loading = false
                if (data.mensajes.data.length > 0){
                    if(this.page==1){
                        this.mensajes = []
                    }
                    this.page += 1;
                    // let ordenados = _.sortBy(data.chats.data, ['created_at'])
                    // this.mensajes = this.mensajes.concat( data.mensajes.data)

                    let ordenados = _.sortBy(data.mensajes.data, ['created_at'])
                    this.mensajes = ordenados.concat( this.mensajes)
                    this.restablecerScroll()
       
                    if(state){
                        state.loaded()
                    }

                } else {
                    if(state){
                        state.complete()
                    }
                }
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        restablecerScroll(){
            var  element = document.getElementById('scrollGeneral')
            if(element){
                if(element.scrollTo){
                    element.scrollTo({
                        top: element.scrollHeight,
                        behavior: "smooth"
                    })
                }
            }
        },
        necesitaDeslizar(){
            const rootElement = document.getElementById('scrollGeneral')
            if(rootElement === undefined || rootElement === null){
                return false
            }
            var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
            return (rootElement.scrollTop / scrollTotal ) > 0.80

        },
        ocultarIcono(){
            var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
            scrollToTopBtn.classList.remove("showBtn")

        },
        handleScroll(){
            console.log('handleScroll');
            var scrollToTopBtn = document.querySelector(".scrollToTopBtn")
            if (this.necesitaDeslizar()){
                scrollToTopBtn.classList.remove("showBtn")
                // Hide button
            } else {
                scrollToTopBtn.classList.add("showBtn")
                // Show button
            }
        },
        async toogleLike(msg){
            try {
                const payload = {
                    id_mensaje: msg.id,
                    created_by: msg.creador.id
                }
                this.loadingLike = true
                const {data:{data}} = await Foros.likeMensaje(payload)
                this.loadingLike = false
                msg.my_like = !data.elimino
                if(data.elimino){
                    msg.likes_count = msg.likes_count -1
                }else{
                    msg.likes_count = msg.likes_count +1
                }

            } catch (e){
                this.loadingLike = true
                this.error_catch(e)
            }
        
        },
        limpiarForo(){
            this.page = 1
            if(this.$refs.infiniteLoading){
                this.page = 1                
                this.$refs.infiniteLoading.stateChanger.reset(); 
            }
            this.mensajes =[]
        }
    }
}
</script>

<style lang="scss" scoped>
.ticket-name{
    background-color: #D9DCE7;    
    color: var(--color-grey);
    border-radius: 0px 20px 20px 0px;
}
.border-grey{
    border: 1px solid var(--color-grey);
}
.triangle-up{
    width: 0px;
    height: 0px;
    border-top: 0px solid transparent;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #FF00F7;
}
.triangle-right{
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-left: 20px solid #FF00F7;
    border-right: 15px solid transparent;
    border-bottom: 15px solid transparent;
}
.estilos-hijos{
        border-left: 1px solid #C5C5C5;
        border-radius: 0px 0px 0px 48px;
}
 .scrollToTopBtn {
    background-color: #4d5d75;
    border: none;
    outline: none !important;
    border-radius: 50%;
    position: fixed;
    right: 30px;
    top: 10px;
    color: white;
    cursor: pointer;
    font-size: 16px;
    line-height: 48px;
    width: 48px;
    /* keep it at the top of everything else */
    z-index: 100;
    /* hide with opacity */
    opacity: 0;
    /* also add a translate effect */
    transform: translateY(100px);
    /* and a transition */
    transition: all .5s ease

 }
 .showBtn {
  opacity: 1;
  transform: translateY(0)
}
</style>