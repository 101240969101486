
import axios from 'axios';

const API_PREFIX = 'cursos';

const ENDPOINTS = {
    listarForosCurso: (payload) => axios.get(`${API_PREFIX}/${payload.id_curso}/foros`),
    crearForo: (payload) => axios.post(`${API_PREFIX}/foros`,payload),
    editarForo: (payload) => axios.put(`${API_PREFIX}/foros`,payload),
    listarMensajesForo: (payload) => axios.get(`${API_PREFIX}/foros/${payload.id_foro}?page=${payload.page}`, payload),
    crearMensajeForo: (payload) => axios.post(`${API_PREFIX}/foros/mensajes`, payload),
    listarDetalleForo: (payload) => axios.get(`${API_PREFIX}/foros/detalle/${payload.id_foro}`),
    likeMensaje: (payload) => axios.post(`${API_PREFIX}/foros/mensajes/likes`,payload),
    insertVisualizaciones: (payload) => axios.post(`visualizaciones`,payload),


};

export default ENDPOINTS;

